.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-body {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

table{
	border: 1px solid black;
	width: 447px;
	height: 450px;
	border-spacing: 0px;
	border-collapse: collapse;
}
tbody {
	border: 0px;
	height: inherit;
	width: inherit;
}

th {
	font-size: 5pc;
	border: 1px solid black;
	width: 148px;
	height: 148px;
	padding: 0;
}

tr {
	display: table-row;
	height: 148px;
}

button {
	background:linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);
	background-color:#33bdef;
	border-radius:6px;
	border:1px solid #057fd0;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px -1px 0px #5b6178;
	display: block;
	position: relative;
	
}
button:hover {
	background:linear-gradient(to bottom, #019ad2 5%, #33bdef 100%);
	background-color:#019ad2;
}
button:active {
	position:relative;
	top:1px;
}

.filler {
	height: 31px;
}
